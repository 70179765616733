<template>
  <div class="container mt-5 text-center">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title text-danger">Payment Unsuccessful</h1>
        <p class="card-text">Unfortunately, your transaction could not be completed. Please try again or contact support.</p>
        <button class="btn btn-primary" @click="retryPayment">Retry Payment</button>
        <button class="btn btn-secondary ml-2" @click="contactSupport">Contact Support</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentUnsuccessful',
  methods: {
    retryPayment() {
      this.$router.push('/patient_dashboard'); 
    },
    contactSupport() {
      this.$router.push('/patient_dashboard'); 
    }
  }
}
</script>

<style scoped>
.card {
  max-width: 100%;
  margin: auto;
  border: 1px solid #dc3545;
}
.card-title {
  color: #dc3545;
}
</style>
